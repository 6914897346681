<template>
  <div>

    <validation-observer

      ref="form"
    >

      <empty-card
        :title="$t('notices.title')"
      >
        <template slot="title">
          <div
            class="d-flex align-items-center"
          >
            <b-button
              variant="dark"
              :disabled="submitting"
              @click="submit()"
            >
              <span :class="{'pr-1': submitting}">{{ $t('forms.save') }}</span>
              <b-spinner
                v-if="submitting"
                small
              />
            </b-button>
          </div>
        </template>
        <div
          class="mt-2"
        >
          <error-alert
            v-if="error"
            :error="error"
          />
          <b-row class="align-items-center">
            <b-col
              md="12"
              class="mb-2"
            >
              <b-form-checkbox
                v-model="form.enabled"
                switch
              >
                {{ $t(`notices.enable`) }}
              </b-form-checkbox>
            </b-col>
            <template v-if="form.enabled">
              <b-col
                md="4"
                class="mb-1"
              >
                <b-form-checkbox
                  v-model="form.with_icon"
                  switch
                >
                  {{ $t(`notices.with-icon`) }}
                </b-form-checkbox>
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <ok-text-input
                  size="md"
                  read-only
                  :label="$t('notices.text-color')"
                  is-required
                >
                  <ok-color-picker v-model="form.text_color" />
                </ok-text-input>
              </b-col>
              <b-col
                md="4"
                class="mb-1"
              >
                <ok-text-input
                  size="md"
                  read-only
                  :label="$t('notices.bg-color')"
                  is-required
                >
                  <ok-color-picker v-model="form.bg_color" />
                </ok-text-input>
              </b-col>
            </template>
          </b-row>
        </div>
      </empty-card>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BSpinner, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'
import ErrorHandler from '@/utils/error-handler'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import OkColorPicker from '@/@bya3/components/inputs/OkColorPicker'
import NoticesAPIs from '@/api/store/notices'

export default {
  name: 'NoticesForm',
  components: {
    OkColorPicker,
    OkTextInput,
    ErrorAlert,
    EmptyCard,
    BButton,
    ValidationObserver,
    BRow,
    BFormCheckbox,
    BCol,
    BSpinner,
  },
  data() {
    return {
      submitting: false,
      sending: false,
      form: {
        enabled: 0,
        text_color: '#ED6B0B',
        bg_color: '#FEFBE8',
        with_icon: 1,
      },
      error: null,
    }
  },
  computed: {
    ...mapGetters({
      notice: 'notices/notice',
    }),
  },
  watch: {
    notice() {
      this.init()
    },
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      this.form = this.notice?.id ? { ...this.notice } : { ...this.form }
      this.form.enabled = !!this.form.enabled
      this.form.with_icon = !!this.form.with_icon
    },
    async submit() {
      this.error = null
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
        return
      }
      const payload = { ...this.form }
      this.submitting = true
      NoticesAPIs.processNotice(payload)
        .then(response => {
          this.$notifySuccess(response.message)
          this.$store.commit('notices/SET_NOTICE', { ...response.notice })
        })
        .catch(error => { this.error = error })
        .finally(() => { this.submitting = false })
    },
  },
}
</script>
