<template>
  <div
    ref="colorpicker"
    :class="`input-group color-picker input-group-${size}`"
  >
    <input
      v-model="colorValue"
      :class="`form-control form-control-${size}`"
      type="text"
      dir="ltr"
      :placeholder="placeholder"
      @focus="showPicker()"
      @input="updateFromInput"
    >
    <color-picker
      v-if="displayPicker"
      v-model="colorValue"
      dir="ltr"
      mode="hex"
      :position="{left: 0, top: '40px'}"
      @change="updateFromPicker"
    />
  </div>
</template>

<script>
export default {
  name: 'OkColorPicker',
  props: {
    value: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      colors: {
        hex: '#000000',
      },
      colorValue: '',
      displayPicker: false,
    }
  },
  watch: {
    colorValue(newVal, oldVal) {
      const colorRegex = /^#[0-9A-F]{6}$/i
      let color = newVal

      if (!colorRegex.test(newVal)) {
        if (colorRegex.test(oldVal)) {
          color = oldVal
          this.colorValue = oldVal
        } else this.colorValue = '#000000'
      }
      if (color) {
        this.updateColors(color)
        this.$emit('input', color)
      }
    },
    value(val) {
      this.setColor(val ?? '#000000')
    },
  },
  mounted() {
    if (this.value || !this.placeholder) {
      this.setColor(this.value || '#000000')
    }
  },
  methods: {
    setColor(color) {
      this.updateColors(color)
      this.colorValue = color
    },
    updateColors(color) {
      if (color.slice(0, 1) === '#') {
        this.colors = {
          hex: color,
        }
      } else if (color.slice(0, 4) === 'rgba') {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',')
        // eslint-disable-next-line no-bitwise
        const hex = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10)).toString(16).slice(1)}`
        this.colors = {
          hex,
          a: rgba[3],
        }
      }
    },
    showPicker() {
      document.addEventListener('click', this.documentClick)
      this.displayPicker = true
    },
    hidePicker() {
      document.removeEventListener('click', this.documentClick)
      this.displayPicker = false
    },
    togglePicker() {
      if (this.displayPicker) {
        this.hidePicker()
      } else {
        this.showPicker()
      }
    },
    updateFromInput() {
      this.updateColors(this.colorValue)
    },
    updateFromPicker(color) {
      this.colors = color
      if (color.rgba.a === 1) {
        this.colorValue = color.hex
      } else {
        this.colorValue = `rgba(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b}, ${color.rgba.a})`
      }
    },
    documentClick(e) {
      const el = this.$refs.colorpicker
      const { target } = e
      if (el !== target && !el.contains(target)) {
        this.hidePicker()
      }
    },
  },
}
</script>

<style lang="scss">
.color-picker {
  .vc-chrome {
    position: absolute;
    top: 35px;
    right: 0;
    z-index: 9;
  }

  .current-color {
    display: inline-block;
    width: 32px;
    height: 100%;
    background-color: #000;
    cursor: pointer;

    border: 1px solid var(--custom-control-border-color);
    border-radius: 0 5px 5px 0;
    border-left: unset;

    .dark-layout & {
      border-color: var(--theme-dark-input-border-color);
      color: var(--theme-dark-input-placeholder-color);
    }
  }
}
</style>
