<template>
  <div>
    <fetching-data-spinner
      :loading="fetching"
      :loading-title="$t('general.loading-data')"
      enable-retry
    />
    <template v-if="!fetching">
      <notices-form />
      <div class="mt-2" />
      <notice-items-table v-if="showTable" />
    </template>
  </div>
</template>
<script>
import NoticesForm from '@/views/user/notices/components/NoticesForm'
import FetchingDataSpinner from '@/@bya3/components/utils/FetchingDataSpinner'
import { mapGetters } from 'vuex'

export default {
  components: {
    FetchingDataSpinner,
    NoticesForm,
    NoticeItemsTable: () => import('@/views/user/notices/NoticeItemsTable'),
  },
  computed: {
    fetching() {
      return !this.$store.getters['notices/noticeFetched']
    },
    ...mapGetters({
      notice: 'notices/notice',
    }),
    showTable() {
      return !!this.notice?.id
    },
  },
  mounted() {
    this.fetchNotice()
  },
  methods: {
    async fetchNotice() {
      await this.$store.dispatch('notices/FETCH_NOTICE')
    },
  },
}
</script>
